import React, { useEffect } from "react";
import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react";
import { RightPageContainer } from "components";
import { Link as RouterLink, useParams } from "react-router-dom";

export const IndicationsIndicated = () => {
  const { _id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <RightPageContainer bgSrc="linear-gradient(to right, #00513E, #00462A, #002D13)" fgSrc={require("assets/imgs/sign-up/fg-05.png")}>
      <VStack flex="1" alignItems="stretch" justifyContent="center" spacing="15px" py="20px">
        <Heading size="lg" textAlign="center">
          Parabéns!
        </Heading>
        <Text textAlign="center">
          Você foi indicado para começar a enconomizar na sua conta de luz! Faça agora seu cadastro e venha fazer parte da transformação
          energética.
        </Text>
        <Box h="20px" />
        <Button as={RouterLink} to={`/quero-economizar/?indicacao=${_id}`} colorScheme="main">
          Iniciar cadastro
        </Button>
      </VStack>
    </RightPageContainer>
  );
};
