import React, { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Center, Heading, HStack, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { Slider } from "components";
import { currency } from "lib";

const Calculadora = () => {
  const [electricityBillAmount, setElectricityBillAmount] = useState(150);
  const [economiaAnual, reducaoCo2KgAnual] = useMemo(() => {
    if (electricityBillAmount < 50) return [0, 0];
    let tarifa = 0.95;
    let fatorReducaoCo2PorKwh = 0.3406;
    let taxaDisponiblidadeBifasico = 50;
    let desconto = 0.15;

    let consumoKwhMesTotal = electricityBillAmount / tarifa;
    let consumoKwhCompensavel = consumoKwhMesTotal - tarifa * taxaDisponiblidadeBifasico;
    let valorCompensavel = tarifa * consumoKwhCompensavel;
    let valorDesconto = desconto * valorCompensavel;
    // let valorComercializadora = valorCompensavel - valorDesconto;
    // let valorTaxaDisponibilidade = tarifa * taxaDisponiblidadeBifasico;
    // let valorTotalConta = valorComercializadora + valorTaxaDisponibilidade;
    let kwhConsumidoMes = consumoKwhCompensavel;
    let kwhConsumidoAno = kwhConsumidoMes * 12;

    let economiaAnual = valorDesconto * 12;
    let reducaoCo2KgAnual = kwhConsumidoAno * fatorReducaoCo2PorKwh;

    return [economiaAnual, parseInt(reducaoCo2KgAnual)];
  }, [electricityBillAmount]);

  return (
    <Center id="calcule-sua-economia" marginTop="-160px" zIndex="9" position="relative">
      <SimpleGrid columns={[1, 2]} borderRadius="lg" maxW="1300px" w="90%" overflow="hidden">
        <VStack bg="main.500" p="40px" spacing="20px">
          <Box w="100%">
            <Heading color="white" fontWeight="600">
              Calcule Sua Economia!
            </Heading>
            <Text color="white" fontSize="lg">
              Quanto você gasta de luz, em média, por mês?
            </Text>
          </Box>
          <Slider value={electricityBillAmount} onChange={(value) => setElectricityBillAmount(value)} sliderTrackBg="main.400" />
          <VStack h="100%" justifyContent="center">
            <Heading size="2xl" color="white">
              {currency.format(electricityBillAmount)}
            </Heading>
          </VStack>
        </VStack>
        <VStack bg="secondary.500" p="40px" spacing="10px">
          <HStack borderWidth="1px" borderColor="main.500" borderRadius="xl" p="20px" w="100%">
            <Text flex="1" fontSize={{ md: "xl" }} color="white" lineHeight="1">
              Em 12 meses você poderá economizar até
            </Text>
            <Heading size={{ md: "lg" }} flex="1" color="white" textAlign="right">
              {currency.format(economiaAnual)}
            </Heading>
          </HStack>
          <Text color="white" pb="10px">
            E ainda contribuirá com o meio ambiente reduzindo até {reducaoCo2KgAnual.toLocaleString()} kg de CO2 lançados no planeta.
          </Text>
          <Button as={RouterLink} to="/quero-economizar" state={{ electricityBillAmount }} size="lg" w="100%" colorScheme="main">
            QUERO ECONOMIZAR!
          </Button>
        </VStack>
      </SimpleGrid>
    </Center>
  );
};

export default Calculadora;
