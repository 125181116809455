import React, { createContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  SlideFade,
  StackDivider,
  useDisclosure,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { Logo } from "components";
import { MdClose, MdMenu } from "react-icons/md";
import { FaChevronUp, FaWhatsapp } from "react-icons/fa";
import Menu from "./menu";

export const HeaderContext = createContext();

export const Header = () => {
  const location = useLocation();
  const [pageYOffset, setPageYOffset] = useState(0);
  const isMinimized = useMemo(() => pageYOffset > 100, [pageYOffset]);
  const [isMobileView] = useMediaQuery("(max-width: 940px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const pathname = useMemo(() => {
    const [__, pathname] = location.pathname.split("/");
    return pathname;
  }, [location.pathname]);

  useEffect(() => {
    const onScroll = () => setPageYOffset(window.pageYOffset);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <HeaderContext.Provider value={{ isMinimized, isMobileView }}>
      <Box h={isMinimized ? "60px" : "80px"} />
      <Center position="fixed" top="0" left="0" w="100%" bg="white" borderBottomWidth="1px" zIndex="99">
        <HStack
          w="1300px"
          h={isMinimized ? "60px" : "80px"}
          px="20px"
          transition="400ms"
          justifyContent="space-between"
          alignItems="center"
        >
          {isMobileView && <IconButton variant="outline" icon={<Icon as={MdMenu} />} isRound onClick={onOpen} />}
          <Link href="/">
            {pathname === "fazendacervejeira" ? (
              <Image
                src={require("assets/imgs/header/logo-fazendacervejeira.png")}
                width={isMinimized ? 180 : 220}
                style={{ transition: "400ms" }}
              />
            ) : (
              <Logo width={isMinimized ? 80 : 120} />
            )}
          </Link>
          <HStack>
            {!isMobileView && <Menu isMinimized={isMinimized} />}
            <IconButton
              isRound
              colorScheme="main"
              icon={<Icon as={FaWhatsapp} />}
              onClick={() => window.open("https://wa.me/5531984257137")}
            />
          </HStack>
        </HStack>
      </Center>

      <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <IconButton variant="outline" icon={<Icon as={MdClose} />} isRound onClick={onClose} />
          </DrawerHeader>
          <DrawerBody pb="20px">
            <VStack divider={<StackDivider />} alignItems="left">
              <Menu isMinimized={isMinimized} />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box position="fixed" bottom="20px" left="20px" zIndex="99">
        <SlideFade in={isMinimized} offsetY="20px">
          <Center
            as={Link}
            href="#home"
            bg="white"
            w="54px"
            h="54px"
            borderRadius="full"
            cursor="pointer"
            borderWidth="1px"
            transition="400ms"
            _hover={{ color: "main.500" }}
          >
            <Icon as={FaChevronUp} transition="400ms" />
          </Center>
        </SlideFade>
      </Box>
    </HeaderContext.Provider>
  );
};
