import React, { memo, useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Select,
  Text,
  useToast,
  VisuallyHidden,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { messages } from "consts";
import { yup, api } from "lib";
import { RightPageContainer, Stages } from "components";
import { MdAttachFile, MdChevronLeft, MdChevronRight, MdClose } from "react-icons/md";
import PdfImage from "assets/imgs/sign-up/pdf.jpg";

const DocumentComponent = memo(({ label, id, value, errorMessage, onChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();

  const handleAttach = useCallback(() => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 2000);
    inputRef.current.click();
  }, [inputRef.current]);

  const handleRemove = useCallback(() => {
    inputRef.current.value = "";
    onChange(null, id);
  }, [onChange, inputRef.current]);

  return (
    <>
      <VisuallyHidden>
        <input ref={inputRef} accept="image/*,application/pdf" type="file" onChange={(e) => onChange(e, id)} />
      </VisuallyHidden>
      <HStack spacing="10px">
        <Button colorScheme="main" variant="outline" leftIcon={<Icon as={MdAttachFile} />} isLoading={isLoading} onClick={handleAttach}>
          {label}
        </Button>
        {value && (
          <Box position="relative">
            <IconButton
              size="xs"
              colorScheme="blackAlpha"
              position="absolute"
              top="-10px"
              right="-10px"
              isRound
              icon={<Icon as={MdClose} />}
              onClick={handleRemove}
            />
            <Image src={value.type === "application/pdf" ? PdfImage : value.location} w="50px" borderRadius="sm" />
          </Box>
        )}
      </HStack>
      <FormErrorMessage pt="5px">{errorMessage}</FormErrorMessage>
    </>
  );
});

export const SignUpDocuments = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = useMemo(() => {
    const [__, pathname] = location.pathname.split("/");
    return pathname;
  }, [location.pathname]);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const toast = useToast();
  const imgs = useMemo(() => {
    switch (pathname) {
      case "quero-economizar":
        return {
          bg: require("assets/imgs/sign-up/bg-03.jpg"),
          fg: require("assets/imgs/sign-up/fg-03.png"),
        };
      case "fazendacervejeira":
        return {
          bg: require("assets/imgs/sign-up/bg-03.jpg"),
          fg: require("assets/imgs/sign-up/fg-03.png"),
        };
    }
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFormData({
      documentType: "rg",
      isAdditionalUc: location.state?.isAdditionalUc === true,
    });
  }, [location.state]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const files = [data.energy_account, data.cnh, data.rg_front, data.rg_back].filter((o) => o);
        await api.upload(`/public/leads/${_id}/files`, files, { groups: files.map((o) => o.group) });
        navigate(`/${pathname}/sucesso/${_id}`, { state: location.state });
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, toast, pathname, location.state]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const shape = {
          energy_account: yup.mixed().required(messages.error.required),
        };
        if (formData.isAdditionalUc === false) {
          shape.documentType = yup.string().required(messages.error.required);
          if (formData.documentType === "rg") {
            shape.rg_front = yup.mixed().required(messages.error.required);
            shape.rg_back = yup.mixed().required(messages.error.required);
          } else {
            shape.cnh = yup.mixed().required(messages.error.required);
          }
        }
        const schema = yup.object().shape(shape);
        await schema.validate(formData, { abortEarly: false });
        handleSaveData(formData);
        setFormErrors({});
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [formData, handleSaveData]
  );

  const handleChangeFile = useCallback(
    (e, id) => {
      if (e) {
        const [file] = Array.from(e.target.files);
        file.location = URL.createObjectURL(file);
        file.group = id;
        setFormData((state) => ({ ...state, [id]: file }));
      } else setFormData((state) => ({ ...state, [id]: undefined }));
    },
    [setFormData]
  );

  return (
    <RightPageContainer bgSrc={imgs.bg} fgSrc={imgs.fg} isLoading={!_id}>
      <Stages activeIndex={2} size={3} />
      <VStack flex="1" alignItems="stretch" justifyContent="center" spacing="15px" py="20px">
        <FormControl isInvalid={formErrors.energy_account}>
          <VStack bg="gray.50" borderRadius="xl" spacing="0" p="20px">
            <Heading size="md">Conta da CEMIG</Heading>
            <Text fontSize="xs" textAlign="center">
              Por favor, anexe uma cópia da sua conta mais recente da CEMIG para que possamos prosseguir com seu contrato.
            </Text>
            <Box h="15px" />
            <DocumentComponent
              label="Anexar conta"
              id="energy_account"
              value={formData.energy_account}
              errorMessage={formErrors.energy_account}
              onChange={handleChangeFile}
            />
          </VStack>
        </FormControl>

        {!location.state?.isAdditionalUc && (
          <>
            <FormControl isRequired={true} isInvalid={formErrors.documentType}>
              <FormLabel fontSize="sm">Tipo do documento</FormLabel>
              <Select
                value={formData.documentType}
                onChange={({ target }) => setFormData((state) => ({ documentType: target.value, isAdditionalUc: state.isAdditionalUc }))}
              >
                <option value="rg">RG</option>
                <option value="cnh">CNH</option>
              </Select>
              <FormErrorMessage>{formErrors.documentType}</FormErrorMessage>
            </FormControl>

            {formData.documentType === "cnh" ? (
              <FormControl isInvalid={formErrors.cnh}>
                <VStack bg="gray.50" borderRadius="xl" spacing="0" p="20px">
                  <Heading size="md">CNH aberta</Heading>
                  <Text fontSize="xs" textAlign="center">
                    Por favor, anexe uma cópia da sua CNH aberta possamos prosseguir com seu contrato.
                  </Text>
                  <Box h="15px" />
                  <DocumentComponent
                    label="Anexar CNH aberta"
                    id="cnh"
                    value={formData.cnh}
                    errorMessage={formErrors.cnh}
                    onChange={handleChangeFile}
                  />
                </VStack>
              </FormControl>
            ) : (
              <FormControl isInvalid={formErrors.rg_front || formErrors.rg_back}>
                <VStack bg="gray.50" borderRadius="xl" spacing="0" p="20px">
                  <Heading size="md">RG frente e verso</Heading>
                  <Text fontSize="xs" textAlign="center">
                    Por favor, anexe uma cópia de frente e verso do RG separadamente. Lembre-se de estar em um local bem iluminado e de não
                    tampar informações importantes.
                  </Text>
                  <Box h="15px" />
                  <DocumentComponent
                    label="Anexar RG frente"
                    id="rg_front"
                    value={formData.rg_front}
                    errorMessage={formErrors.rg_front}
                    onChange={handleChangeFile}
                  />
                  <Box h="10px" />
                  <DocumentComponent
                    label="Anexar RG verso"
                    id="rg_back"
                    value={formData.rg_back}
                    errorMessage={formErrors.rg_back}
                    onChange={handleChangeFile}
                  />
                </VStack>
              </FormControl>
            )}
          </>
        )}
      </VStack>
      <HStack>
        <Button size="sm" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
          voltar
        </Button>
        <Box flex="1" />
        <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdChevronRight} />} isLoading={isLoadingSaveData} onClick={handleSubmit}>
          concluir
        </Button>
      </HStack>
    </RightPageContainer>
  );
};
