import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import _ from "lodash";
import { messages } from "consts";
import { yup, api } from "lib";
import { AsyncSelect, RightPageContainer, Stages } from "components";
import { useApiGet } from "hooks";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

let loadCitiesTimeout = null;

export const SignUpAddress = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = useMemo(() => {
    const [__, pathname] = location.pathname.split("/");
    return pathname;
  }, [location.pathname]);
  const [data] = useApiGet(useMemo(() => ({ path: `/public/leads/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingZipCode, setIsLoadingZipCode] = useState(false);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const document = useMemo(() => {
    if (formData.type === "pj") return { label: "CNPJ", mask: "99.999.999/9999-99" };
    return { label: "CPF", mask: "999.999.999-99" };
  }, [formData.type]);
  const toast = useToast();
  const imgs = useMemo(() => {
    switch (pathname) {
      case "quero-economizar":
        return {
          bg: require("assets/imgs/sign-up/bg-02.jpg"),
          fg: require("assets/imgs/sign-up/fg-02.png"),
        };
      case "fazendacervejeira":
        return {
          bg: require("assets/imgs/sign-up/bg-02.jpg"),
          fg: require("assets/imgs/sign-up/fg-02.png"),
        };
    }
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleZipCodeChange = useCallback(
    async ({ target }) => {
      try {
        const zipCode = target.value.replace(/\D/g, "");
        setFormData((state) => ({ ...state, address: { ...state.address, zipCode } }));
        if (zipCode.length !== 8) return;
        setIsLoadingZipCode(true);
        const address = await api.get(`/common/locales/${zipCode}`);
        setFormData((state) => ({ ...state, address: { ...state.address, ...address } }));
      } finally {
        setIsLoadingZipCode(false);
      }
    },
    [setFormData]
  );

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.put(`/public/leads/${_id}`, data);
        navigate(`/${pathname}/documentacao/${_id}`);
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
        setIsLoadingSaveData(false);
      }
    },
    [_id, toast, pathname]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const schema = yup.object().shape({
          type: yup.string().required(messages.error.required),
          document: yup.string().required(messages.error.required),
          address: yup.object().shape({
            zipCode: yup.string().required(messages.error.required),
            street: yup.string().required(messages.error.required),
            number: yup.string().required(messages.error.required),
            neighborhood: yup.string().required(messages.error.required),
            city: yup.string().required(messages.error.required),
          }),
        });
        await schema.validate(formData, { abortEarly: false });
        handleSaveData(formData);
        setFormErrors({});
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [formData, handleSaveData]
  );

  const handleLoadCities = useCallback((search, cb) => {
    clearTimeout(loadCitiesTimeout);
    loadCitiesTimeout = setTimeout(async () => {
      const response = await api.get("/common/cities", { params: { search } });
      cb(response ?? []);
    }, 1000);
  }, []);

  return (
    <RightPageContainer bgSrc={imgs.bg} fgSrc={imgs.fg} isLoading={!data}>
      <Stages activeIndex={1} size={3} />
      <VStack flex="1" alignItems="stretch" justifyContent="center" spacing="15px" py="20px">
        <FormControl isRequired={true} isInvalid={formErrors.type}>
          <FormLabel fontSize="sm">Tipo</FormLabel>
          <Select value={formData.type ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
            <option value="pf">Pessoa física</option>
            <option value="pj">Pessoa jurídica</option>
          </Select>
          <FormErrorMessage>{formErrors.type}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors.document}>
          <FormLabel fontSize="sm">{document.label}</FormLabel>
          <Input
            as={InputMask}
            inputMode="numeric"
            mask={document.mask}
            value={formData.document ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
          />
          <FormErrorMessage>{formErrors.document}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors["address.zipCode"]}>
          <FormLabel fontSize="sm">CEP</FormLabel>
          <InputGroup>
            <Input as={InputMask} inputMode="numeric" mask="99999-999" value={formData.zipCode} onChange={handleZipCodeChange} />
            {isLoadingZipCode && (
              <InputRightElement>
                <Spinner />
              </InputRightElement>
            )}
          </InputGroup>
          <FormErrorMessage>{formErrors["address.zipCode"]}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors["address.street"]}>
          <FormLabel fontSize="sm">Rua</FormLabel>
          <Input
            value={formData.address?.street ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, street: target.value } }))}
          />
          <FormErrorMessage>{formErrors["address.street"]}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors["address.number"]}>
          <FormLabel fontSize="sm">Número</FormLabel>
          <Input
            value={formData.address?.number ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, number: target.value } }))}
          />
          <FormErrorMessage>{formErrors["address.number"]}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors["address.neighborhood"]}>
          <FormLabel fontSize="sm">Bairro</FormLabel>
          <Input
            value={formData.address?.neighborhood ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, neighborhood: target.value } }))}
          />
          <FormErrorMessage>{formErrors["address.neighborhood"]}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors["address.city"]}>
          <FormLabel fontSize="sm">Cidade</FormLabel>
          <AsyncSelect
            isInvalid={formErrors.address?.city ?? ""}
            value={{ name: formData.address?.city ?? "" }}
            defaultOptions
            loadOptions={handleLoadCities}
            placeholder="--Selecione"
            onChange={({ name }) => setFormData((state) => ({ ...state, address: { ...state.address, city: name } }))}
            getOptionValue={({ name }) => name}
            formatOptionLabel={({ name }) => name}
          />
          <FormErrorMessage>{formErrors["address.city"]}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formErrors["address.complement"]}>
          <FormLabel fontSize="sm">Complemento</FormLabel>
          <Input
            value={formData.address?.complement ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, complement: target.value } }))}
          />
          <FormErrorMessage>{formErrors["address.complement"]}</FormErrorMessage>
        </FormControl>
      </VStack>
      <HStack>
        <Button size="sm" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
          voltar
        </Button>
        <Box flex="1" />
        <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdChevronRight} />} isLoading={isLoadingSaveData} onClick={handleSubmit}>
          continuar
        </Button>
      </HStack>
    </RightPageContainer>
  );
};
