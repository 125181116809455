import React from "react";
import { SimpleGrid, Link, Box, Text, Center, HStack, IconButton, Icon, VStack } from "@chakra-ui/react";
import { Logo } from "components";
import { FaEnvelope, FaFacebookSquare, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";

export const Footer = () => {
  return (
    <Center bg="#F6F6F6">
      <Box maxW="1300px" w="100%" p="40px">
        <SimpleGrid columns={[1, 4]} py="60px">
          <Box>
            <Logo width={250} />
            <HStack py="20px" spacing="5px">
              <IconButton
                size="lg"
                variant="outline"
                target="_blank"
                icon={<Icon as={FaFacebookSquare} />}
                onClick={() => window.open("https://www.facebook.com/clicklivre.energia")}
                isRound
              />
              <IconButton
                size="lg"
                variant="outline"
                target="_blank"
                icon={<Icon as={FaInstagram} />}
                onClick={() => window.open("https://www.instagram.com/clicklivre_energia")}
                isRound
              />
              <IconButton
                size="lg"
                variant="outline"
                target="_blank"
                icon={<Icon as={FaTwitter} />}
                onClick={() => window.open("https://www.twitter.com/clicklivre")}
                isRound
              />
              <IconButton
                size="lg"
                variant="outline"
                target="_blank"
                icon={<Icon as={FaWhatsapp} />}
                onClick={() => window.open("https://wa.me/5531984257137")}
                isRound
              />
            </HStack>
          </Box>
          <VStack alignItems="flex-start">
            <Text color="gray.600">Rua dos Goitacazes, 15 - 901 - Centro, Belo Horizonte - MG, CEP 30190-050</Text>
            <Link href="mailto:contato@clicklivre.com.br" _hover={{ textDecoration: "none", color: "main.500" }}>
              <HStack>
                <Center w="26px" h="26px" borderRadius="full" bg="gray.500">
                  <Icon as={FaEnvelope} boxSize="12px" color="white" />
                </Center>
                <Text>contato@clicklivre.com.br</Text>
              </HStack>
            </Link>
            <Link href="https://wa.me/5531984257137" target="_blank" _hover={{ textDecoration: "none", color: "main.500" }}>
              <HStack>
                <Center w="26px" h="26px" borderRadius="full" bg="main.500">
                  <Icon as={FaWhatsapp} color="white" />
                </Center>
                <Text>(31) 9 8425-7137</Text>
              </HStack>
            </Link>
          </VStack>
          <Box />
          <Box />
        </SimpleGrid>
        <Center>
          <Text>&copy; Todos os direitos reservados | ClickLivre {new Date().getFullYear()}.</Text>
        </Center>
      </Box>
    </Center>
  );
};
