import React, { useEffect } from "react";
import Banners from "./Banners";
import Calculadora from "./Calculadora";
import QuemSomos from "./QuemSomos";
import ClickClub from "./ClickClub";
import Downloads from "./Downloads";
import ComoFunciona from "./ComoFunciona";
import Depoimentos from "./Depoimentos";
import Duvidas from "./Duvidas";

export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Banners />
      <Calculadora />
      <ComoFunciona />
      <ClickClub />
      <Depoimentos />
      <QuemSomos />
      <Duvidas />
      <Downloads />
    </>
  );
};
