import React, { useCallback } from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import * as Pages from "./pages";
import { Footer, Header, Redirect } from "components";

const Container = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const AppProvider = () => {
  const renderSigUpRoutes = useCallback((path) => {
    return (
      <Route path={path}>
        <Route index={true} element={<Pages.SignUpGeneral />} />
        <Route path="inicio" element={<Pages.SignUpGeneral />} />
        <Route path="inicio/:_id" element={<Pages.SignUpGeneral />} />
        <Route path="endereco/:_id" element={<Pages.SignUpAddress />} />
        <Route path="documentacao/:_id" element={<Pages.SignUpDocuments />} />
        <Route path="sucesso/:_id" element={<Pages.SignUpSuccess />} />
        <Route path="*" element={<Navigate to="inicio" replace={true} />} />
      </Route>
    );
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Container />}>
          <Route index={true} element={<Pages.Home />} />

          <Route path="indique-e-ganhe">
            <Route index={true} element={<Pages.IndicationsGenerate />} />
            <Route path="compartilhe" element={<Pages.IndicationsShare />} />
          </Route>

          {renderSigUpRoutes("quero-economizar")}
          {renderSigUpRoutes("fazendacervejeira")}

          <Route path="indicacoes/:_id" element={<Pages.IndicationsIndicated />} />
        </Route>

        <Route path="estatuto" element={<Redirect href="/downloads/Contrato-Consorcio_Carvalho_de_Energia_Solar.pdf" />} />

        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppProvider;
