import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { VStack, Box, Heading, Text, Icon, Link, HStack } from "@chakra-ui/react";
import Lottie from "react-lottie";
import { RightPageContainer } from "components";
import { FaWhatsapp, FaFacebookSquare } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { MdMail } from "react-icons/md";
import animationData from "assets/lottie/success.json";

export const SignUpSuccess = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <RightPageContainer bgSrc={require("assets/imgs/sign-up/bg-03.jpg")} fgSrc={require("assets/imgs/sign-up/fg-03.png")}>
      <VStack flex="1" justifyContent="center" spacing="0" py="20px" pb="40px">
        <Lottie
          options={{ loop: false, autoplay: true, animationData }}
          isClickToPauseDisabled={true}
          width={200}
          height={200}
          style={{ cursor: "default" }}
        />
        <Heading size="lg" textAlign="center">
          Sucesso!
        </Heading>
        {location.state?.isAdditionalUc ? (
          <Text fontSize="sm" textAlign="center">
            Sua nova Unidade Consumidora foi adicionada com sucesso! Iremos avaliar a solicitação e em breve entraremos em contato. Ficamos
            felizes por você estar expandindo seus negócios com a ClickLivre.
          </Text>
        ) : (
          <Text fontSize="sm" textAlign="center">
            Seu cadastro foi finalizado com sucesso. Em breve um de nossos consultores entrará em contato para mais detalhes.
          </Text>
        )}
        <Box h="10px" />
        <Text fontSize="sm" textAlign="center">
          Caso tenha alguma dúvida, entre em contato através de um dos nossos canais de atendimento abaixo.
        </Text>
        <Box h="20px" />
        <VStack alignItems="flex-start" spacing="15px">
          <Link href="https://wa.me/5531984257137" target="_blank">
            <HStack>
              <Icon as={FaWhatsapp} color="green.500" boxSize="20px" />
              <Text fontSize="sm" fontWeight="600">
                (31) 9 8425-7137
              </Text>
            </HStack>
          </Link>
          <Link href="https://instagram.com/clicklivre_energia" target="_black">
            <HStack>
              <Icon as={BsInstagram} color="#d62976" boxSize="20px" />
              <Text fontSize="sm" fontWeight="600">
                @clicklivre_energia
              </Text>
            </HStack>
          </Link>
          <Link href="https://facebook.com/clicklivre.energia" target="_black">
            <HStack>
              <Icon as={FaFacebookSquare} color="#4267B2" boxSize="20px" />
              <Text fontSize="sm" fontWeight="600">
                @clicklivre.energia
              </Text>
            </HStack>
          </Link>
          <Link href="mailto:contato@clicklivre.com.br" target="_black">
            <HStack>
              <Icon as={MdMail} color="gray.500" boxSize="20px" />
              <Text fontSize="sm" fontWeight="600">
                contato@clicklivre.com.br
              </Text>
            </HStack>
          </Link>
        </VStack>
      </VStack>
    </RightPageContainer>
  );
};
